@tailwind base;
@tailwind components;
@tailwind utilities;


.dot {
  border-radius: 0px !important;
  width: 20px !important;
  height: 4px !important;
  /* background-color: rgb(104, 198, 236) !important; */
}

h,
input,
div,
span,
label,
button,
p {
  font-family: "Inter", sans-serif !important;
}

table span {
  font-size: 13px !important;
}

.css-1tzeee1 {
  opacity: 1 !important;
}

.css-qpxlqp {
  right: inherit !important;
}


.dropbtn {
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
}



.dropdown {

  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  width: 100%;
  top: 110%;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content span {
  color: black;
  padding: 7px 13px;
  text-decoration: none;
  display: block;
}



.show {
  display: block;
}


.Available {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px !important;
  gap: 10px;
  box-shadow: none !important;
  background: #D3FCD2 !important;
  border-radius: 4px;
  /* border: 1px solid white !important; */
  color: #065E49 !important;

}

/* .Available:hover {
  border: 1px solid #22C55E !important;
} */

.Occupied {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px !important;
  gap: 10px;
  box-shadow: none !important;
  /* border: 1px solid white !important; */
  background: #FFE9D5 !important;
  border-radius: 4px;
  color: #FF5630 !important;

}

/* .Occupied:hover {
  border: 1px solid #FF5630;
} */
.dateTimepicker>div {
  height: 40px;
  font-size: 12px;
  font-family: "Inter", sans-serif !important;
  border-radius: 8px !important;
  border-color: #DFE3E8 !important;
}

.Booked {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px !important;
  gap: 10px;
  box-shadow: none !important;
  background: #FFF5CC !important;
  /* border: 1px solid white !important; */
  border-radius: 4px;
  color: #FFAB00 !important;

}

/* .Booked:hover {
  border: 1px solid #FFAB00 !important;
} */

.Reserved {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px !important;
  gap: 10px;
  box-shadow: none !important;
  background: #CCF4FE !important;
  border-radius: 4px;
  /* border: 1px solid white !important; */
  color: #078DEE !important;

}

/* 
.Reserved:hover {
  border: 1px solid #078DEE !important;
} */

.Maintenance {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px !important;
  gap: 10px;
  box-shadow: none !important;
  background: #EFD6FF !important;
  border-radius: 4px;
  /* border: 1px solid white !important; */
  color: #27097A !important;

}

/* .Maintenance:hover {
  border: 1px solid #27097A !important;
} */

.inter {
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  /* font-weight: 600 !important; */
}


.sideBarLink {
  /* Frame 52 */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 5px;
  gap: 2px;
  margin-bottom: 10px;
  border-radius: 5px;
}



.sublinkvisible {
  left: -1000%;
  display: none;
  transition: all 0.5s ease-in-out;
}

.exportpdf {
  right: -1000%;
  display: none;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

.sideBarLink:hover>.sublinkvisible {
  left: 70px;
  display: block;

}

.sideBarLink:hover>.exportpdf {
  right: 0px;
  top: 100%;
  display: block;

}


input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

.Mui-selected>div>span {
  background-color: #E9F3FF;
}

.required::after {
  position: absolute;
  content: "*";
  color: red;
  margin: 2px 4px;

}


.error {
  font-size: 11px;
  color: red;
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  /* padding-left: 5px; */
  font-weight: 600 !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  /* Adjust this value to make the scrollbar thinner */
}

/* Track */
::-webkit-scrollbar-track {
  /* background: transparent; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  height: 100px;
  background: #c5c4c47e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #078DEE; */
}


.MuiInputBase-colorPrimary::after {
  content: none !important;
}

.css-1eng3o7-MuiPaper-root,
.css-gtppdf {
  border: 0 !important;
}

.MuiFormControl-root label {
  height: 16px;
}


.custom-font .MuiButtonBase-root {
  font-size: 12px !important;
}

.custom-font li{
  position: relative;
  margin: 0 4px;
  font-weight: 600;
  border-radius: 8px !important;
  border-bottom-color: transparent ;
}
/* .custom-font li:nth-child(2)::after,.custom-font li:nth-child(5)::after{
  content: "";
  bottom: 0;
  position: absolute;
  width: 100%;
  left: 0;
  border-bottom-width: 1px ;
  border-bottom-color: #DFE3E8  ;
} */

.custom-font li:hover {
  background-color: #eaeaea;
}

div[role="presentation"] > div:has(> ul) , div[role="dialog"] {
  border-radius: 8px !important;
}

.custom-font .svg {
  font-size: 13px !important;
}



/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

span[aria-label="Clear filter"]> {
  position: relative;
}

span[aria-label="Clear filter"]>button {
  position: absolute;
  right: 0px;
  top: 0px;


}

[data-testid="ArrowDropDownIcon"]+div>span[aria-label="Clear filter"]>button {
  position: absolute;
  right: 20px;
  top: 0px;
}

span[aria-label="Clear filter"]>button>svg {

  width: 13px;
}

.border-zero fieldset {
  border: 0px !important;
}

tr .MuiOutlinedInput-notchedOutline {
  border-color: #DFE3E8 !important;
}


.border-zero {
  outline: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 8px;
}

.border-zero.errorBoder:hover {
  outline: 1px solid rgba(0, 0, 0, 0.87);
}

.border-zero:hover {
  outline: 1px solid rgba(0, 0, 0, 0.87);
}

.border-zero:focus-within {
  outline-color: #078DEE;
  outline: 2px solid #078DEE;
}

.border-zero.errorBoder {
  outline: 1px solid #FF5630;
}




.errorBoder>fieldset {
  border: 1.5px solid #FF5630;
}

.errorBoder span>input {
  border: 1.5px solid #FF5630;
}

/* For modern browsers */
table input::placeholder,
.css-segi59 {
  color: rgb(0, 0, 0) !important;
  opacity: 1;
  /* Firefox */
  font-weight: 550;
}

table .dateTimepicker>div {
  font-weight: 550;
  opacity: 0.5;

}

/* For Microsoft Edge (Legacy) */
table input::-ms-input-placeholder {
  color: rgb(0, 0, 0) !important;
  opacity: 1;
  font-weight: 550;
}

/* For Internet Explorer 10-11 */
table input:-ms-input-placeholder {
  color: rgb(0, 0, 0) !important;
  opacity: 1;
  font-weight: 550;
}

.disable_Edit{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
}


button:disabled > p{
  color: #A1ACB8 !important;
}

